export * from './hooks';
export * from './plugins';
export * from './types';

import { createApiClient } from '../codegen/person-api';

export { schemas as apiSchemas } from '../codegen/person-api';

const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
if (!API_HOST) {
  throw new Error('Please provide the API host');
}

export const api = createApiClient(API_HOST);
