import { Typography } from '@mui/material';

export type TFaceComparisonResultPanelProps = {
  statusMessage?: string;
  similarityPercentage?: number;
};

export default function FaceComparisonResultPanel({
  statusMessage,
  similarityPercentage,
}: Readonly<TFaceComparisonResultPanelProps>) {
  return (
    <>
      {similarityPercentage && (
        <Typography variant="h6">{`Similarity: ${similarityPercentage}%`}</Typography>
      )}
      {statusMessage && <Typography variant="h6">{statusMessage}</Typography>}
    </>
  );
}
