import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';
import IdentityDocumentPortrait, {
  TIdentityDocumentPortraitProps,
} from './IdentityDocumentPortrait';
import LivenessPanel, { TLivenessPanelProps } from './Liveness/LivenessPanel';
import FaceComparisonResultPanel, {
  TFaceComparisonResultPanelProps,
} from './FaceComparisonResultPanel';

export enum PortraitType {
  IDENTITY_DOCUMENT = 'identityDocument',
  LIVENESS = 'liveness',
}

export type TPortraitPanelProps = {
  livenessPanelProps: TLivenessPanelProps;
  identityDocumentPortraitProps: TIdentityDocumentPortraitProps;
  faceComparisonResultProps?: TFaceComparisonResultPanelProps;
  initialActiveTab?: PortraitType;
};

export default function PortraitPanel({
  initialActiveTab = PortraitType.LIVENESS,
  livenessPanelProps,
  faceComparisonResultProps,
  identityDocumentPortraitProps,
}: Readonly<TPortraitPanelProps>) {
  const [portraitType, setPortraitType] = useState<PortraitType>(initialActiveTab);

  useEffect(() => {
    setPortraitType(initialActiveTab);
  }, [initialActiveTab]);

  const handlePortraitTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newPortraitType: PortraitType,
  ) => {
    // see https://mui.com/material-ui/react-toggle-button/#enforce-value-set
    if (newPortraitType != null) {
      setPortraitType(newPortraitType);
    }
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      minWidth={tokens.size2xs}
      maxWidth={tokens.size2xs}
      padding={tokens.spacingXs}
      gap={tokens.spacingXs}
    >
      {faceComparisonResultProps && <FaceComparisonResultPanel {...faceComparisonResultProps} />}
      <ToggleButtonGroup
        exclusive
        value={portraitType}
        onChange={handlePortraitTypeChange}
        color="primary"
      >
        <ToggleButton value={PortraitType.LIVENESS} size="small">
          Face Check
        </ToggleButton>
        <ToggleButton value={PortraitType.IDENTITY_DOCUMENT} size="small">
          ID Portrait
        </ToggleButton>
      </ToggleButtonGroup>
      {portraitType === PortraitType.LIVENESS && <LivenessPanel {...livenessPanelProps} />}
      {portraitType === PortraitType.IDENTITY_DOCUMENT && (
        <IdentityDocumentPortrait {...identityDocumentPortraitProps} />
      )}
    </Stack>
  );
}
