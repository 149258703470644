import { api } from '@verifime/api-definition';
import {
  TaskRequestPanel as TaskRequestPanelComponent,
  TTaskRequestPanelProps as TTaskRequestPanelComponentProps,
} from '@verifime/components';
import { qrcodeUrl } from '@verifime/utils';
import { useEffect, useState } from 'react';

export type TTaskRequestPanelProps = {
  taskId: string;
};

export function TaskRequestPanel({ taskId }: Readonly<TTaskRequestPanelProps>) {
  const [taskRequestPanelProp, setTaskRequestPanelProp] =
    useState<TTaskRequestPanelComponentProps>(undefined);
  useEffect(() => {
    if (!taskId) {
      return;
    }

    api.getV1taskId({ params: { id: taskId } }).then((response) => {
      setTaskRequestPanelProp({
        taskUrl: response.trackingReferenceUrl,
        taskUrlQrCodeUrl: qrcodeUrl(response.trackingReferenceUrl),
      });
    });
  }, [taskId]);

  return (
    <>
      {taskRequestPanelProp && <TaskRequestPanelComponent key={taskId} {...taskRequestPanelProp} />}
    </>
  );
}
