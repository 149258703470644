import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import OpenInFullIcon from '@mui/icons-material/OpenInFullRounded';
import { formatToDDMMMYYYYHHMMAAA, stringUtils } from '@verifime/utils';
import { useEffect, useState } from 'react';
import { ColoredIcon } from '../../ColoredIconAndChip';
import { IdentityStatus, IdentityStatusChip } from '../../Identities';
import NameValueDisplay from '../../NameValueDisplay';
import LivenessViewer, { TlivenessViewerProps } from './LivenessViewer';
import { TLivenessTransaction } from './type';

export type TLivenessPanelProps = {
  showStatus?: boolean;
  fetchLivenessTransaction?: () => Promise<Partial<TLivenessTransaction>>;
  requestDetail?: TRequestDetailProps;
} & Omit<TlivenessViewerProps, 'portraitImageUrl' | 'footer' | 'checkedDateTime'>;

function toIdentityStatus(status: TLivenessTransaction['status']): IdentityStatus {
  switch (status) {
    case 'CONFIRMED':
      return IdentityStatus.VERIFIED;
    case 'CANNOT_CONFIRM':
      return IdentityStatus.REJECTED;
    case 'NO_RESULT':
      return IdentityStatus.DRAFT;
  }
}

type TRequestDetailProps = {
  onRequest: () => void;
  requestBy: string;
  requestDateTime: string;
  onShowTaskRequestLink?: () => void;
};

const RequestButton = ({
  requestBy,
  requestDateTime,
  onRequest,
  onShowTaskRequestLink,
}: TRequestDetailProps) => {
  // We decided to use if the callback exists or not to decide whether display this request button
  if (!onRequest) {
    return;
  }

  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      {requestDateTime && (
        <NameValueDisplay
          displayType="horizontal"
          name="Requested on"
          value={formatToDDMMMYYYYHHMMAAA(requestDateTime)}
        />
      )}
      {requestBy && (
        <NameValueDisplay
          displayType="horizontal"
          name="by user"
          value={stringUtils.ellipsis(requestBy)}
        />
      )}
      {onShowTaskRequestLink && (
        <Button
          size="medium"
          variant="outlined"
          endIcon={<OpenInFullIcon />}
          onClick={onShowTaskRequestLink}
        >
          Show REQUEST LINK
        </Button>
      )}
      <Button startIcon={<ColoredIcon iconName="pending" />} variant="outlined" onClick={onRequest}>
        {/** Just use one of requestDateTime and requestBy to determine whether this is the first request or not */}
        {requestDateTime ? 'Request again' : 'Request now'}
      </Button>
    </Stack>
  );
};

export default function LivenessPanel({
  showStatus = false,
  fetchLivenessTransaction,
  ...props
}: TLivenessPanelProps) {
  const [livenessTransaction, setLivenessTransaction] = useState<TLivenessTransaction | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchLivenessTransaction?.().then(setLivenessTransaction);
  }, [fetchLivenessTransaction]);

  return (
    <Stack gap={tokens.spacingXs} padding={tokens.spacingXs}>
      {showStatus && (
        <Stack direction="row" justifyContent="flex-start" spacing={tokens.spacing3xs}>
          <Typography variant="h6">Liveness Check</Typography>
          <Box>
            {livenessTransaction?.status && (
              <IdentityStatusChip
                status={toIdentityStatus(livenessTransaction?.status)}
                isCompact
              />
            )}
          </Box>
        </Stack>
      )}
      {!livenessTransaction ? (
        <CircularProgress />
      ) : (
        <LivenessViewer
          portraitImageUrl={livenessTransaction.portraitImageUrl}
          checkedDateTime={livenessTransaction.checkedDateTime}
          action={
            livenessTransaction.status !== 'CONFIRMED' && <RequestButton {...props.requestDetail} />
          }
          {...props}
        />
      )}
    </Stack>
  );
}
