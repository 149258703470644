'use client';

import OpenInFullIcon from '@mui/icons-material/OpenInFullRounded';
import QrCodeIcon from '@mui/icons-material/QrCodeRounded';
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode, useEffect, useState } from 'react';
import CopyableText from './CopyableText';
import { IconLinkOpenNew } from './IconLinkOpenNew';

export const enum InviteCustomerDisplayVariant {
  QR_CODE = 'QR_CODE',
  TEXT = 'TEXT',
}

export type TShowQrCodeVariantAction = () => void;

export type TInviteCustomerProps = {
  code: string;
  inviteCustomerLinkGenerator: (code: string) => {
    inviteCustomerLink: URL;
    qrCodeDownloadLink: URL;
  };
  variant: InviteCustomerDisplayVariant;
  showQrCodeVariant: TShowQrCodeVariantAction;
};

type TInviteCustomerDisplayProps = {
  inviteCustomerLink: string;
  qrCodeDataUrlPromise: Promise<string>;
};

type TShowQrCodeVariantProps = {
  showQrCodeVariant: TShowQrCodeVariantAction;
};

function SignUpCodeDisplay({ code }: { code: string }) {
  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      <Typography variant="body1">Your invite code is</Typography>
      <CopyableText textVariant="h3" text={code} />
    </Stack>
  );
}

function SignUpLinkDisplay({
  inviteCustomerLink,
  inviteCustomerLinkText,
  children,
}: {
  children?: ReactNode;
  inviteCustomerLinkText: string;
} & Omit<TInviteCustomerDisplayProps, 'qrCodeDataUrlPromise'>) {
  const [copied, setCopied] = useState(false);

  async function copyUrlToClipboard(url: string) {
    if (url && navigator.clipboard) {
      await navigator.clipboard.writeText(url);
      setCopied(true);
    }
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false); // Hide "copied to clipboard" tooltip after 3 seconds
      }, 3000);
    }
  }, [copied]);

  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      {children && (
        <Typography variant="body1" color="text.secondary">
          {children}
        </Typography>
      )}

      <Typography variant="body2" align="center">
        {inviteCustomerLink}
      </Typography>
      <Tooltip
        title="copied to clipboard"
        open={copied}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <Link
          sx={{
            cursor: 'pointer',
          }}
          onClick={async () => copyUrlToClipboard(inviteCustomerLink)}
        >
          {inviteCustomerLinkText}
        </Link>
      </Tooltip>
    </Stack>
  );
}

function QRCodeDisplay({
  inviteCustomerLink,
  qrCodeDataUrlPromise,
  showQrCodeVariant,
  showQRCode,
}: { showQRCode: boolean } & TInviteCustomerDisplayProps & TShowQrCodeVariantProps) {
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>(undefined);
  useEffect(() => {
    if (!showQRCode || !inviteCustomerLink) {
      return;
    }

    qrCodeDataUrlPromise.then((qrCodeDataUrl) => {
      if (qrCodeDataUrl !== '') {
        setQrCodeDataUrl(qrCodeDataUrl);
      }
    });
  }, [showQRCode, qrCodeDataUrlPromise]);

  const qrCodeBoxSx: BoxProps['sx'] = {
    width: tokens.size2xs,
    height: tokens.size2xs,
  };

  return (
    <Stack alignItems="center" gap={tokens.spacingBase}>
      {showQRCode ? (
        <>
          {qrCodeDataUrl ? (
            <Box component="img" sx={qrCodeBoxSx} alt={inviteCustomerLink} src={qrCodeDataUrl} />
          ) : (
            <Stack sx={qrCodeBoxSx} justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          )}
        </>
      ) : (
        <Button
          size="medium"
          variant="outlined"
          startIcon={<QrCodeIcon />}
          endIcon={<OpenInFullIcon />}
          fullWidth={true}
          onClick={showQrCodeVariant}
        >
          Show QR Code
        </Button>
      )}
    </Stack>
  );
}

function InviteCustomerTextQRCodeVariantDisplay({
  inviteCustomerLink,
  qrCodeDataUrlPromise,
  code,
  showQrCodeVariant,
}: {
  code: string;
} & TInviteCustomerDisplayProps &
  TShowQrCodeVariantProps) {
  return (
    <Stack gap={tokens.spacingXl}>
      <Stack gap={tokens.spacingXs}>
        <SignUpCodeDisplay code={code} />
        <QRCodeDisplay
          showQRCode
          qrCodeDataUrlPromise={qrCodeDataUrlPromise}
          inviteCustomerLink={inviteCustomerLink}
          showQrCodeVariant={showQrCodeVariant}
        />
      </Stack>
      <SignUpLinkDisplay
        inviteCustomerLink={inviteCustomerLink}
        inviteCustomerLinkText="Can't scan code? Click to copy Link"
      />
    </Stack>
  );
}

function InviteCustomerTextTextVariantDisplay({
  inviteCustomerLink,
  code,
  showQrCodeVariant,
}: {
  code: string;
} & TInviteCustomerDisplayProps &
  TShowQrCodeVariantProps) {
  return (
    <Stack gap={tokens.spacingXl}>
      <Stack gap={tokens.spacingXs}>
        <SignUpCodeDisplay code={code} />
        <SignUpLinkDisplay
          inviteCustomerLink={inviteCustomerLink}
          inviteCustomerLinkText="Copy Link"
        />
      </Stack>
      <QRCodeDisplay
        showQRCode={false}
        qrCodeDataUrlPromise={Promise.resolve('')}
        inviteCustomerLink={inviteCustomerLink}
        showQrCodeVariant={showQrCodeVariant}
      />
    </Stack>
  );
}

function InviteLinkQRCodeDownloadLink({
  qrCodeDataUrlPromise,
}: Pick<TInviteCustomerDisplayProps, 'qrCodeDataUrlPromise'>) {
  const [qrCodeData, setQrCodeData] = useState<string>(undefined);
  useEffect(() => {
    qrCodeDataUrlPromise.then((qrCodeDataUrl) => {
      if (qrCodeDataUrl !== '') {
        setQrCodeData(qrCodeDataUrl);
      }
    });
  }, [qrCodeDataUrlPromise]);
  return (
    <>
      {qrCodeData && (
        <Link download="QR.jpg" href={qrCodeData} target="_blank">
          Download QR code as image
        </Link>
      )}
    </>
  );
}

function InviteCustomerCode({
  variant,
  code,
  inviteCustomerLinkGenerator,
  showQrCodeVariant,
}: {
  variant: InviteCustomerDisplayVariant;
} & Omit<TInviteCustomerProps, 'qrCodeDownloadLink'> &
  TShowQrCodeVariantProps) {
  const { inviteCustomerLink, qrCodeDownloadLink } = inviteCustomerLinkGenerator(code);
  const qrCodeDataUrlPromise = new Promise<string>((resolve) => {
    if (!qrCodeDownloadLink) {
      resolve('');
    }
    fetch(qrCodeDownloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        resolve(blobUrl);
      });
  });
  const inviteCustomerTextDisplayProps = {
    code,
    inviteCustomerLink: inviteCustomerLink.href,
    showQrCodeVariant,
    qrCodeDataUrlPromise,
  };

  return (
    <Stack alignItems="center" gap={tokens.spacingXl}>
      <Stack alignItems="center" gap={tokens.spacingXl}>
        {variant === InviteCustomerDisplayVariant.QR_CODE && (
          <InviteCustomerTextQRCodeVariantDisplay {...inviteCustomerTextDisplayProps} />
        )}
        {variant === InviteCustomerDisplayVariant.TEXT && (
          <InviteCustomerTextTextVariantDisplay {...inviteCustomerTextDisplayProps} />
        )}
        <InviteLinkQRCodeDownloadLink qrCodeDataUrlPromise={qrCodeDataUrlPromise} />
      </Stack>
      <Divider flexItem />
      <IconLinkOpenNew href="https://www.verifime.com/clientcustomeronboarding">
        Learn more about onboarding
      </IconLinkOpenNew>
    </Stack>
  );
}

export default function InviteCustomer({
  variant,
  showQrCodeVariant,
  ...props
}: TInviteCustomerProps) {
  return (
    <Box alignItems="center" gap={tokens.spacingXl}>
      <InviteCustomerCode showQrCodeVariant={showQrCodeVariant} variant={variant} {...props} />
    </Box>
  );
}
