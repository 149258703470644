import { enqueueSnackbar, OptionsObject } from 'notistack';

type NotificationOptions = Partial<Omit<OptionsObject, 'variant'>>;

const defaultSnackbarProps: NotificationOptions = {
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  TransitionProps: {
    direction: 'down',
  },
};

type NotificationVariant = OptionsObject['variant'];

export const enqueSuccessNotification = (message: string) => {
  enqueNotificationWithOptions('successNotification', message, defaultSnackbarProps);
};

export const enqueErrorNotification = (message: string) => {
  enqueNotificationWithOptions('errorNotification', message, defaultSnackbarProps);
};

export const enqueNotificationWithOptions = (
  variant: NotificationVariant,
  message: string,
  options: NotificationOptions,
) => {
  enqueueSnackbar(message, {
    variant,
    ...defaultSnackbarProps,
    ...options,
  });
};
