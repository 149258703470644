import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import { CircularProgress } from '@mui/material';

const DOWNLOAD_QR_CODE_FILENAME = 'QR.png';

export type TTaskRequestPanelProps = {
  taskUrl: string;
  taskUrlQrCodeUrl: string;
};

function TaskLinkDisplay({ taskUrl, taskLinkText }: { taskUrl: string; taskLinkText: string }) {
  const [copied, setCopied] = useState(false);

  async function copyUrlToClipboard(url: string) {
    if (url && navigator.clipboard) {
      await navigator.clipboard.writeText(url);
      setCopied(true);
    }
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false); // Hide "copied to clipboard" tooltip after 3 seconds
      }, 3000);
    }
  }, [copied]);

  return (
    <Stack alignItems="center" gap={tokens.spacingXs}>
      <Typography variant="body2" align="center">
        {taskUrl}
      </Typography>
      <Tooltip
        title="copied to clipboard"
        open={copied}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <Link
          sx={{
            cursor: 'pointer',
          }}
          onClick={async () => copyUrlToClipboard(taskUrl)}
        >
          {taskLinkText}
        </Link>
      </Tooltip>
    </Stack>
  );
}

export default function TaskRequestPanel({
  taskUrl,
  taskUrlQrCodeUrl,
}: Readonly<TTaskRequestPanelProps>) {
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>(undefined);
  useEffect(() => {
    if (!taskUrlQrCodeUrl) {
      return;
    }
    fetch(taskUrlQrCodeUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setQrCodeDataUrl(blobUrl);
      });
  }, [taskUrlQrCodeUrl]);
  const qrCodeBoxSx = {
    height: tokens.sizeXs,
    width: tokens.sizeXs,
  };

  return (
    <Box alignItems="center" gap={tokens.spacingXl}>
      <Stack alignItems="center" gap={tokens.spacingXl}>
        <Typography variant="body1">Request face check link</Typography>
        {qrCodeDataUrl ? (
          <Box component="img" sx={qrCodeBoxSx} alt={taskUrl} src={qrCodeDataUrl} />
        ) : (
          <Stack sx={qrCodeBoxSx} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        <TaskLinkDisplay taskUrl={taskUrl} taskLinkText="Click to copy Link" />
        {qrCodeDataUrl && (
          <Link download={DOWNLOAD_QR_CODE_FILENAME} href={qrCodeDataUrl} target="_blank">
            Download QR code as image
          </Link>
        )}
      </Stack>
    </Box>
  );
}
