import { api, TDocumentType } from '@verifime/api-definition';
import { TFileUploadStatus, UploadForVerification } from '@verifime/components';
import { DocumentType } from '@verifime/utils';

const documentUploadServiceCalls = () => ({
  loadExistingUploadedFiles: (entityId: string, docType: DocumentType, signal?: AbortSignal) =>
    api.getV1fileEntityIdDocType({
      params: { entityId, docType },
      signal,
    }),

  handleRetrieveFileUploadUrls: (
    entityId: string,
    documentType: DocumentType,
    filenameList: string[],
  ) =>
    api.postV1filepresign({
      entityId,
      documentType,
      filenameList,
    }),

  handleUploadFile: (fileUploadStatus: TFileUploadStatus) =>
    fetch(fileUploadStatus.fileUploadUrl, {
      method: 'PUT',
      headers: { 'Content-Type': fileUploadStatus.fileType },
      body: fileUploadStatus.file,
    }),

  handleDeleteFile: (entityId: string, docType: DocumentType, fileId: string) =>
    api.deleteV1fileEntityIdDocTypeFileId([
      {
        params: { fileId, entityId, docType },
      },
    ]),
});

export default function DocumentUploader({
  entityId,
  documentType,
  onSubmit,
}: Readonly<{
  entityId: string;
  documentType: TDocumentType;
  onSubmit: (fileId: string) => Promise<void>;
}>) {
  return (
    <UploadForVerification
      entityId={entityId}
      documentType={documentType}
      onSubmit={onSubmit}
      documentUploadServiceCalls={documentUploadServiceCalls()}
      submitButtonText="Submit"
      isShowCancelButton={false}
    />
  );
}
