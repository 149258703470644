import { IdentityTypeCode, TOption } from '@verifime/utils';
import { TTrustType } from './types';

export const APPLICATION_NAME = 'VerifiMe Admin Portal';

export enum EntityStatus {
  Empty = 'Empty',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export type AllowedEntityStatus = Exclude<EntityStatus, EntityStatus.Empty>;

export enum FormStatus {
  UNKNOWN,
  UNFILLED,
  FILLED,
  DOING_UPDATE,
}

export enum UnregulatedTrustType {
  FamilyTrust = 'Family Trust',
  CharitableTrust = 'Charitable Trust',
  TestamentaryTrust = 'Testamentary Trust',
  UnitTrust = 'Unit Trust',
  OtherType = 'Other Type',
}

export enum RegulatedTrustType {
  RegisteredManagedInvestmentScheme = 'Registered Managed Investment Scheme',
  UnregisteredManagedInvestmentScheme = 'Unregistered Managed Investment Scheme',
  RegulatedUnderACommonwealthStatutoryRegulator = 'Regulated Under A Commonwealth Statutory Regulator',
  GovernmentSuperannuationFund = 'Government Superannuation Fund',
}

export enum ExchangeListingType {
  DomesticExchange = 'Domestic Exchange',
  InternationalExchange = 'International Exchange',
  Unlisted = 'Unlisted',
}

const enumToOptions = (sourceEnum: any): TOption[] => {
  return Object.entries(sourceEnum).map(
    ([k, v]) =>
      ({
        code: k,
        label: v,
      } as TOption),
  );
};

export const TRUST_TYPE_OPTIONS: {
  [key in IdentityTypeCode]?: TTrustType[];
} = {
  [IdentityTypeCode.Trust_Unregulated]: enumToOptions(UnregulatedTrustType),
  [IdentityTypeCode.Trust_Regulated]: enumToOptions(RegulatedTrustType),
};

export const EXCHANGE_LISTING_TYPE_OPTIONS = enumToOptions(ExchangeListingType);

export const basicFieldsPrefix = 'basicSepa';
export const passportFieldsPrefix = 'passportSepa';
export const driverLicenceFieldsPrefix = 'driverLicenceSepa';
export const addressFieldsPrefix = 'addressSepa';
export const principalPlaceOfBusinessFieldsPrefix = 'principalPlaceOfBusinessSepa';
export const medicareCardFieldsPrefix = 'medicareCardSepa';
