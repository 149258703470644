import AdminIcon from '@mui/icons-material/SecurityRounded';
import InviteIcon from '@mui/icons-material/EmailRounded';
import ProvisionIcon from '@mui/icons-material/PersonAddRounded';
import ClientIcon from '@mui/icons-material/CorporateFareRounded';
import PermissionRequestIcon from '@mui/icons-material/SecurityRounded';
import { TNavItem } from '@verifime/components';
import { IdentityTypeCode, TIdentityTypeCode } from '@verifime/utils';

export const ADMIN_PATH = '/admin';

export enum Role {
  Customer = 'customer',
  Admin = 'admin',
}

export const NAV_ITEM_ADMIN: TNavItem = {
  path: `${ADMIN_PATH}`,
  label: 'Admin',
  icon: AdminIcon,
  role: Role.Admin,
};

export const NAV_ITEM_INVITE: TNavItem = {
  path: `${ADMIN_PATH}/invite`,
  label: 'Invite',
  icon: InviteIcon,
  role: Role.Admin,
};

export const NAV_ITEM_PROVISION: TNavItem = {
  path: `${ADMIN_PATH}/provision`,
  label: 'Provision',
  icon: ProvisionIcon,
  role: Role.Admin,
};

export const NAV_ITEM_CLIENTS: TNavItem = {
  path: `${ADMIN_PATH}/clients`,
  label: 'Clients',
  icon: ClientIcon,
  role: Role.Admin,
};

export const NAV_ITEM_PERMISSION_REQUEST: TNavItem = {
  path: `${ADMIN_PATH}/permission-request`,
  label: 'Permission Request',
  icon: PermissionRequestIcon,
  role: Role.Admin,
};

export const NAV_ITEMS: TNavItem[] = [
  NAV_ITEM_ADMIN,
  NAV_ITEM_INVITE,
  NAV_ITEM_PROVISION,
  NAV_ITEM_PERMISSION_REQUEST,
  NAV_ITEM_CLIENTS,
];

export function generateCustomerDetailsHref(customerId: string, entityType: TIdentityTypeCode) {
  if (entityType === IdentityTypeCode.Individual_Domestic) {
    return `${ADMIN_PATH}/individual/dashboard/${customerId}`;
  }
  return `${ADMIN_PATH}/organisation/dashboard/${customerId}`;
}
